import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCpSBm0ehBiEr4muaISG3Rgm_UNR6Bfd5M",
  authDomain: "klatenkab-b26a2.firebaseapp.com",
  projectId: "klatenkab-b26a2",
  storageBucket: "klatenkab-b26a2.appspot.com",
  messagingSenderId: "403502709904",
  appId: "1:403502709904:web:b02f2ef8dee8cb1b0f2c15",
  measurementId: "G-KB1K4C63J8"
}

if (!firebase.apps.length) { 
  firebase.initializeApp(config)
}

export default (ctx, inject) => {
  inject('firebase', firebase)
}