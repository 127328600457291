import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7131bb26 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _9a2105e4 = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _8996fda4 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _ed5b7552 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _3f24695c = () => interopDefault(import('../pages/review/index.vue' /* webpackChunkName: "pages/review/index" */))
const _0ec2d3c7 = () => interopDefault(import('../pages/service/index.vue' /* webpackChunkName: "pages/service/index" */))
const _778af9bc = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _5fde0d45 = () => interopDefault(import('../pages/auth/checkforgot.vue' /* webpackChunkName: "pages/auth/checkforgot" */))
const _5b32e60e = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _45fe7503 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _04c3e0f9 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _6532101d = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _1c8e9c00 = () => interopDefault(import('../pages/auth/successforgot.vue' /* webpackChunkName: "pages/auth/successforgot" */))
const _0e8fd264 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _57ee89ec = () => interopDefault(import('../pages/auth/verification/_slug.vue' /* webpackChunkName: "pages/auth/verification/_slug" */))
const _f0c8b7e2 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _f3e53390 = () => interopDefault(import('../pages/submission/_id.vue' /* webpackChunkName: "pages/submission/_id" */))
const _63b78da1 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _7131bb26,
    name: "dashboard"
  }, {
    path: "/guide",
    component: _9a2105e4,
    name: "guide"
  }, {
    path: "/map",
    component: _8996fda4,
    name: "map"
  }, {
    path: "/news",
    component: _ed5b7552,
    name: "news"
  }, {
    path: "/review",
    component: _3f24695c,
    name: "review"
  }, {
    path: "/service",
    component: _0ec2d3c7,
    name: "service"
  }, {
    path: "/auth/check",
    component: _778af9bc,
    name: "auth-check"
  }, {
    path: "/auth/checkforgot",
    component: _5fde0d45,
    name: "auth-checkforgot"
  }, {
    path: "/auth/forgot",
    component: _5b32e60e,
    name: "auth-forgot"
  }, {
    path: "/auth/login",
    component: _45fe7503,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _04c3e0f9,
    name: "auth-register"
  }, {
    path: "/auth/success",
    component: _6532101d,
    name: "auth-success"
  }, {
    path: "/auth/successforgot",
    component: _1c8e9c00,
    name: "auth-successforgot"
  }, {
    path: "/auth/reset/:slug?",
    component: _0e8fd264,
    name: "auth-reset-slug"
  }, {
    path: "/auth/verification/:slug?",
    component: _57ee89ec,
    name: "auth-verification-slug"
  }, {
    path: "/news/:slug",
    component: _f0c8b7e2,
    name: "news-slug"
  }, {
    path: "/submission/:id?",
    component: _f3e53390,
    name: "submission-id"
  }, {
    path: "/",
    component: _63b78da1,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
